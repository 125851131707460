<template>
  <div>
    <template>
      <advertisement-top-banner-text />
    </template>
    <section class="contact-page policy-content section-b-space">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-sm-12 text-justify">
            <h2 class="text-left mb-3 mt-4">{{ title1 }}</h2>
            <ul class="eb-useing-gumtree">
              <li>
                A. Except as set out above or under any applicable returns
                policy and subject to any rights you have under applicable laws
                that cannot be excluded or limited by these T&Cs,
              </li>

              <li>
                B. We shall not be liable and you shall not be entitled to
                reject goods except for:
                <ol>
                  <li>
                    1. Damage to or loss of goods or any part thereof in transit
                    (where the goods are carried by our own transport or by a
                    carrier on our behalf) where notified to us within 7 working
                    days of receipt of the goods;
                  </li>
                  <li>
                    2. Defects in goods (not being defects caused by any act,
                    neglect or default on your part) notified in writing to us
                    within 7 days of receipt of the goods.
                  </li>
                </ol>
              </li>
              <li>
                C. Where these is a shortage or failure to deliver, or any
                defect in or damage to a goods or service, we may at our option:
                <ol>
                  <li>
                    1. In the case of goods shortage or non-delivery, make good
                    for any such shortage or non-delivery and/or
                  </li>
                  <li>
                    2. in the case of damage or any defect(s) in the goods and
                    in accordance -
                    <ol>
                      <li>
                        i. Replace or repair the goods upon you returning the
                        goods; or;
                      </li>
                      <li>
                        ii. Refund the price paid in respect of any goods proved
                        to be damaged or defective.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                D. Your rights of replacement of any goods thereof which are
                found to be defective will (except where agreed otherwise) be
                negated or rendered void where:
                <ol>
                  <li>
                    1. Goods have been altered by persons other than the
                    manufacturer, us or any authorized dealer; and/or
                  </li>
                  <li>
                    2. Defective goods have not been returned together with full
                    details in writing of the alleged defects within 7 days from
                    the date on which such goods were delivered; and/or
                  </li>
                  <li>
                    3. Defects are due (whftolly or partially) to mistreatment,
                    improper use or storage or failure to observe any
                    manufacturers' instructions or other directions issued or
                    made available by us in connection with the delivered goods.
                  </li>
                </ol>
              </li>
              <li>
                E. Any correspondence in respect of return or exchange of
                defective goods or services shall be made to<mark>
                  @Jalshri.com</mark
                >
                within the time limit as described here-in-above. For all such
                Feedback / Complain, contact our quality manager at 000000 or
                email at<mark>@Jalshri.com</mark>
                or write at above mentioned address including image of the
                product, batch no. & date of manufacturing
              </li>
              <li>
                F. If customer is eligible for Refund, we will process your
                refund within 15 business days of receiving the returned product
                in its original packaging. The refund period includes time taken
                to complete the refund process by Jalshri but excludes any
                delays on part of banks/ card issuers.
              </li>
              <li>
                G. Orders once placed and confirmed cannot be changed or
                cancelled. Payment made towards such orders shall not be
                refunded & goods shall be delivered as per shipping policy.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- Section ends -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      title1: "eParisheva Return, Refund & Cancelation Policy",
      errors: [],
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
.policy-content .eb-useing-gumtree {
  position: relative;
}
.policy-content h2 {
  color: #533333;
  font-weight: 600;
}
.policy-content .eb-device-terms li {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  position: relative;
  margin-bottom: 10px;
  display: list-item;
  list-style: circle;
  margin-left: 1.4%;
}
.policy-content .eb-useing-gumtree li {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  display: list-item;
  list-style: none;
}
.policy-content .eb-useing-gumtree li i {
  position: absolute;
  top: 3px;
  left: 0;
  color: #828282;
  font-size: 14px;
  background-color: #d7eef8;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
}
.flaticon-checked:before {
  content: "✓";
}
</style>
